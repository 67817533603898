import React from 'react';
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {StyledModal, EmojiContainer, ImageWrap, Text } from './styles';
import {StyledBody} from '../ChallengeDetailsV2/participantsInChallengePopUp/styles';
import { ImageUrl } from '../../utils/constants';
import { round } from 'lodash';
import { withTranslation } from 'react-i18next';

class MoreRecognition extends React.Component {

  render() {
    const {showModal, onClose, collectSelectedRecognition, exemplifiesValues, t} = this.props;

    return(
      <StyledModal 
        show={showModal}
        onHide={onClose}
        noBorder={1}
      >
        <Modal.Header closeButton>
          <div>
            <p>{t("Recognitions")}</p>
          </div>
        </Modal.Header>
        <StyledBody>
          <EmojiContainer length={exemplifiesValues.length > 3 ? round(((exemplifiesValues.length) - 3 )/ 2 ) : 0}>
            {exemplifiesValues && exemplifiesValues.length > 3  ? exemplifiesValues.slice(3,exemplifiesValues.length).map((emoji) => (
              <div key={emoji.id} onClick={() => collectSelectedRecognition(emoji.core_value, emoji.id, emoji.image)}>
                <ImageWrap>{emoji.image ? <img src={`${ImageUrl}/${emoji.image}`} /> : <p>{' '}</p>}</ImageWrap>
                <Text>{t(emoji.core_value)}</Text>
              </div>
            )) : t('No data Available ')}
          </EmojiContainer>
        </StyledBody>
      </StyledModal>
    );
  }
}

MoreRecognition.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  collectSelectedRecognition: PropTypes.func,
  exemplifiesValues: PropTypes.array,
  t: PropTypes.func
}

export default (withTranslation()(MoreRecognition));