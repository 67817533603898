/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  StyledModal, CalendarHeader, CalendarWrapper, ConfirmVisitText, ButtonContainer, ButtonV2
} from '../PeopleHomeV2/ManageBuddies/styles';

class WarningPopup extends Component {
  constructor(props) {
    super(props);
  }

  handleRecognition = ()=>{
    const { handlePeerModal, hidePhotoVideoPopup, onClose } = this.props;
    hidePhotoVideoPopup();
    handlePeerModal();
    onClose();
  } 

  render() {
    const { showModal, onClose } = this.props;
    return (
      <StyledModal show={showModal ? true : false} width={"400px"}>
        <CalendarHeader>
          <div>{"Warning"}</div>
        </CalendarHeader>
        <CalendarWrapper padding="0 25px 25px 25px">
          {
            <ConfirmVisitText>
              Are you sure you want to jump<br/>to Peer Recognition Page ?
            </ConfirmVisitText>
          }
          {
            <div className='subtext'>
              whatever you have added in this create<br/>
              post page will be deleted if you click on<br/>
              yes button.
            </div>
          }  
          <ButtonContainer>
            <ButtonV2
              background="#005c87"
              onClick={() => this.handleRecognition()}
              width="100%"
              margin="0 0 10px 0"
              backgroundHover="#005C87"
              colorHover="white"
              color="white"
              border="1"
            >
              {"Yes"}
            </ButtonV2>
            <ButtonV2
              border="1"
              background="transparent"
              color="#005C8799"
              onClick={() => onClose()}
              width="100%"
              backgroundHover="#005C87"
              colorHover="white"
            >
              {"No, I want to stay"}
            </ButtonV2>
          </ButtonContainer>
        </CalendarWrapper>
      </StyledModal>
    );
  }
}

WarningPopup.propTypes = {
  showModal: PropTypes.string,
  onClose: PropTypes.func,
  deleteSocialInspiration: PropTypes.func,
  feed: PropTypes.any,
  updationList: PropTypes.string,
  deleteUser: PropTypes.func,
  t: PropTypes.func,
  sendFriendRequest: PropTypes.func,
  getSuggestionsList: PropTypes.func,
  socialCallBack: PropTypes.func,
  removeBuddy: PropTypes.func,
  showpending: PropTypes.bool,
  showAddBuddy: PropTypes.bool,
  buddyDetails: PropTypes.array,
  hidePhotoVideoPopup: PropTypes.func,
  handlePeerModal: PropTypes.func
};

export default WarningPopup;